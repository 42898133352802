import React, { Component } from 'react'

class Banner extends Component {
    render() {
        return (
            <section className="hero-slider-wrapper home-one-slider">
                <div className="single-hero-slide text-white hero-slide1">
                    <div className="container">
                        <div className="row" className="logo-row">
                            <div className="col-12">
                            <img src={require("../assets/img/logo-raia-viva-170.png") } alt="RaiaViva" style={{height:170}}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-12 col-md-10">
                                <div className="hero-slide-left">
                                    <h1>Tenha uma piscina infinita na sua própria casa!</h1>
                                    <p>Aquelas idas e vindas de borda a borda acabaram! Aproveite melhor a sua piscina com um sistema de natação contracorrente e permanente!</p>
                                    <a href="#saiba-mais" className="theme-btn btn_black">Conheça mais</a>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 col-md-10 offset-md-2 offset-lg-0">
                                <div className="hero-slide-right">                                    
                                    <div className="video-responsive">
                                    <iframe src="https://www.youtube.com/embed/Rw4Yy9WS1BA?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0" frameborder="0" allowfullscreen/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Banner
