import React, { Component } from 'react'
import Banner from './Banner'
import ConhecaMais from './ConhecaMais'
import ProvaSocial from './ProvaSocial'
import Contato from './Contato'

class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <Banner />
               
                <section className="promo-content-section promo-bg section-padding section-como-funciona">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-12">
                                <img style={{width:'100%'}} src={require("../assets/img/img-raia-viva.jpg")} alt="" />
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="promo-text mt-5 mt-lg-0">
                                    <h2>Como funciona a Raiaviva?</h2>
                                    <p>
                                        Através de um sistema hidráulico acionado por um motor de 5CV, que produz um turbilhão internamente a um gabinete instalado junto à borda da sua piscina. Esse fluxo de água que vem do fundo e das laterais do aparelho é devolvida ao usuário em forma de uma forte corrente d´água que pode ser regulada em infinitas velocidades.
                                    </p>
                                
                                    <a href="#contato" className="theme-btn">Entre em contato</a>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ConhecaMais />
                <ProvaSocial />
                <Contato />                
            </React.Fragment>
        )
    }
}

export default Home
