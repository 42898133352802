import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import ReactPixel from 'react-facebook-pixel';

function getAllUrlParams(url) {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

class Contato extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
            // name: 'Name',
            // // email: 'Email',
            // phone: 'Phone',
            // subject: 'Subject',
            // message: 'Message'
        };
    }

    myChangeName = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    myChangeEmail = (event) => {
        this.setState({
            email: event.target.value
        })
    }

    myChangePhone = (event) => {
        this.setState({
            phone: event.target.value
        })
    }

    myChangeSubject = (event) => {
        this.setState({
            subject: event.target.value
        })
    }

    myChangeMessage = (event) => {
        this.setState({
            message: event.target.value
        })
    }

    heandleSubmit = (event) => {
        
        event.preventDefault();
        this.setState({
            loading: true
        })
       
        const formData = require('form-data');
        const Mailgun = require('mailgun.js');
        const mailgun = new Mailgun(formData);
        const mg = mailgun.client({username: 'api', key: '6282a7923c704328060325ec91e82485-c50f4a19-a3dcd7b8'});
       

        let utmSource = getAllUrlParams(document.location.href).utm_source;
        if (utmSource == undefined){
            utmSource = '';
        }

        mg.messages.create('raiaviva.com.br', {
            from: "Site Raiaviva <raiaviva10@gmail.com>",
            to: ["raiaviva10@gmail.com"],
            //to: ["app@luvik.com.br"],
            subject: "Novo contato do site",
            html: "<h1>Novo contato do site</h1><p><strong>Nome:</strong> "+this.state.name+"<br/> <strong>Email:</strong> "+this.state.email+"<br/> <strong>Telefone:</strong> "+this.state.phone+"<br/><strong>Origem:</strong> "+utmSource+"<br/> <strong>Assunto:</strong> "+this.state.subject+"<br/> <strong>Mensagem:</strong><br/> "+this.state.message+"</p>"

        }).then(msg => {
            console.log(msg)
            
            const advancedMatching = { em: ''+this.state.email };
            const options = {
              autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
              debug: false, // enable logs
            };
            ReactPixel.init('7345497382188398', advancedMatching, options);
            ReactPixel.track('Lead');

            window.gtag_report_conversion();
            this.setState({
                classe: 'email-sucesso',
                texto: 'Mensagem enviada com sucesso!',
                loading: false
            });       

        }).catch(err => {
            console.log(err);
            this.setState({
                classe: 'email-erro',
                texto: 'Erro ao enviar a mensagem.<br/> Envie um email para raiaviva10@gmail.com',
                loading: false
            });        
      });
    
    }

    render() {

        var loading = this.state.loading ?  <img src={require("../assets/img/loading.gif")} alt="carregando..."  style={{width:100}}/>  : '';

        return (
            <section className="appointment-wrapper section-padding" id="contato">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="section-title-one">
                                <h1>Entre em contato</h1>
                            </div>                            
                        </div>
                    </div>
                    <div className="contact-information">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                                    <div className="single-contact-info icon1">
                                        <div className="c-icon">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        </div>
                                        <div className="c-info">
                                            <h4>Endereço</h4>
                                            <p>Mococa - SP</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                                    <div className="single-contact-info icon2">
                                        <div className="c-icon">
                                            <FontAwesomeIcon icon={faAt} />
                                        </div>
                                        <div className="c-info">
                                            <h4>Email</h4>
                                            <p>raiaviva10@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                                    <div className="single-contact-info icon3">
                                        <div className="c-icon">
                                            <FontAwesomeIcon icon={faPhone} />
                                        </div>
                                        <div className="c-info">
                                            <h4>Telefone</h4>
                                            <p>+55 19 9.9783.7453</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-md-12">                            
                            <div className="appointment-form">
                                <form className="row" onSubmit={this.heandleSubmit}>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <input
                                            type='text'
                                            value={this.state.name}
                                            onChange={this.myChangeName}
                                            placeholder='Nome'
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <input 
                                            type='email'
                                            value={this.state.email} 
                                            onChange={this.myChangeEmail} placeholder='E-mail' required/>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <input 
                                            type='text'
                                            value={this.state.phone} 
                                            onChange={this.myChangePhone} placeholder='Telefone' required/>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <input 
                                            type='text'
                                            value={this.state.subject}
                                            onChange={this.myChangeSubject} 
                                        placeholder='Assunto ' required/>
                                    </div>
                                    <div className="col-lg-12 col-12">
                                        <textarea
                                            value={this.state.message} 
                                            onChange={this.myChangeMessage}
                                         placeholder='Observação' required/>
                                    </div>
                                    <div className="col-lg-12 col-12 text-center">                                         
                                         <p className={this.state.classe}>{this.state.texto}</p>
                                         <button className="submit-btn" type="submit">Enviar mensagem </button>
                                        {loading}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        )
    }
}

export default Contato
