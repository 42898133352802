import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer-wrapper footer-one">
                <div className="footer-copyright-wrapper text-center">
                    <p>Raiaviva &copy; 2022</p>
                    <p>CNPJ: 30.749.986/0001-68</p>
                </div>
            </footer>
        )
    }
}

export default Footer
