import React, { Component } from 'react';
import SectionTitleOne from './SectionTitleOne';

class ProvaSocial extends Component {
    render() {

        let depoimentos = [
            {
                id: 1,
                urlVideo: "qISmCk8sRXY",
                name: "Pedro Spajari",
            },
            {
                id: 2,
                urlVideo: "_U8C7VPNDbA",
                name: "Eduardo Altilio"
            },
            {
                id: 3,
                urlVideo: "WQIe78_P6vQ",
                name: "Marina Altilio"
            },  
            {
                id: 4,
                urlVideo: "P6uR1uqdPSI",
                name: "Teresa Iório Venturi"
            },      
        ];

        return (

            <section id="prova-social" className="doctors-wrapper section-padding section-bg ">
                <div className="container">

                    <div className="text-white">
                    <SectionTitleOne BigTitle="Opinião de quem já usou!" />
                    </div>

                    <div className="row text-center">

                        {
                            depoimentos.map((member, index) => {
                                
                                return (
                                    index < 4 ? 
                                    <div className="col-md-6 col-lg-6 col-12" key={member.id}>
                                        <div className="single-doctor">
                                            <div className="doctor-profile">
                                                <div className="video-responsive">
                                                <iframe src={"https://www.youtube.com/embed/"+member.urlVideo+"?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0"} frameborder="0" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                            <div className="doctor-info">
                                                <h4>{member.name}</h4>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    :
                                    ''
                                )
                            })
                        }

                      
                    </div>
                </div>
            </section>
        )
    }
}

export default ProvaSocial
