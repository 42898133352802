import React, { Component } from 'react';
import SectionTitleOne from './SectionTitleOne';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faWater } from '@fortawesome/free-solid-svg-icons'
import { faPlug } from '@fortawesome/free-solid-svg-icons'
import { faSwimmer } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faBatteryThreeQuarters } from '@fortawesome/free-solid-svg-icons'

class ConhecaMais extends Component {
    

    render() {
       
        return (
            <section id="saiba-mais" className="services-wrapper section-padding">                
                <div className="container">
                    <SectionTitleOne BigTitle="Aproveite ao máximo a sua piscina!" />

                    <div className="row">

                        <div className="col-md-6 col-12 col-lg-6 col-xl-4">
                            <div className="single-service-box">
                                <div className="service-icon" style={{color:'#141845'}}>
                                <FontAwesomeIcon icon={faWater} />
                                </div>
                                <h3>Funciona em qualquer piscina?</h3>
                                <p>Sim, o aparelho funciona em piscinas que tenham medidas mínimas de 2,0 m de largura por 4,0 m de comprimento para nadadores com altura em torno de 1,75 m. O mesmo não está projetado para ser retirado e removido, porém, com sistema especial de fixação sob consulta, eventualmente, será possível removê-lo.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-6 col-xl-4">
                            <div className="single-service-box">
                                <div className="service-icon" style={{color:'#141845'}}>
                                <FontAwesomeIcon icon={faTachometerAlt} />
                                </div>
                                <h3>Como é possível ajustar a velocidade da corrente d´água?</h3>
                                <p>Através do simples giro de um botão, acondicionado a uma caixa plástica conectada a um inversor de frequência e que pode ser levado para a beira da piscina para controle do próprio usuário. Velocidades entre 1:45seg e 00:51seg para 100 m podem ser atingidas pelo aparelho.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-6 col-xl-4">
                            <div className="single-service-box">
                                <div className="service-icon" style={{color:'#141845'}}>
                                <FontAwesomeIcon icon={faPlug} />
                                </div>
                                <h3>E a energia que o aparelho consome?</h3>
                                <p>Como exemplo: levando-se em consideração que o motor elétrico tem 3,7 kW (5 CV), e usando o aparelho na sua máxima potência durante 2 horas por dia, 3 vezes por semana em 4 semanas por mês, a um custo de R$0,68 por kWh, teremos o custo mensal aproximado de R$88,00.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-6 col-xl-4">
                            <div className="single-service-box">
                                <div className="service-icon" style={{color:'#141845'}}>
                                <FontAwesomeIcon icon={faSwimmer} />
                                </div>
                                <h3>Porque o fluxo da Raiaviva é super eficaz?</h3>
                                <p>Diferente de alguns produtos existentes no mercado, a grade de saída d´água tem 50 cm de largura por 45 cm de altura, sendo toda esta área coberta pela correnteza, envolvendo totalmente o torso do(a) nadador(a) provocando uma boa resistência ao nado.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-6 col-xl-4">
                            <div className="single-service-box">
                                <div className="service-icon" style={{color:'#141845'}}>
                                <FontAwesomeIcon icon={faBatteryThreeQuarters} />
                                </div>
                                <h3>E a durabilidade da Raiaviva?</h3>
                                <p>Cada aparelho é produzido quase que de forma artesanal, desde o corte, dobra e solda das chapas plásticas, sua unidade hidráulica, sua caixa de comando com inversor de frequência, tornando-a um sistema robusto e eficaz. Seus componentes são em aço inoxidável, polipropileno, bases e tintas anticorrosivas, resina epóxi e fibra de vidro.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-6 col-xl-4">
                            <div className="single-service-box">
                                <div className="service-icon" style={{color:'#141845'}}>
                                <FontAwesomeIcon icon={faThumbsUp} />
                                </div>
                                <h3>Garantia</h3>
                                <p>O aparelho tem garantia total de 01 (um) ano a partir da sua instalação.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default ConhecaMais
